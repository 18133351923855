/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import NDCFooter from '../headers-footers/NDCFooter';
import ConnectedNDCHeader from '../headers-footers/NDCHeader';
import PDAPFooter from "../headers-footers/PDAPFooter";

export class NDCPage extends Component {
  componentDidMount() {
    this.sendPageView(this.props.location);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.listen(this.sendPageView.bind(this));
  }

  sendPageView(location) {
    this.props.trackPageView(location.pathname);
  }

  isPDAP= window.location.hostname.includes("principaldentalaccess");
  isStaging = window.location.hostname.includes("staging");

  render() {
    return (
        <div>
          <ConnectedNDCHeader isPDAP={this.isPDAP} isStaging={this.isStaging}/>
          <div className="container-fluid">
            <Row>
              <Col xs={12} mdOffset={2} md={8}>
                {this.props.children}
              </Col>
            </Row>
          </div>
          <div className="footer">
            {this.isPDAP ? (
                <PDAPFooter isStaging={this.isStaging}/>
            ) : (
                <NDCFooter/>
            )}
          </div>

        </div>
    );
  }
}

NDCPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
  trackPageView: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, ownProps) => ({
  location: ownProps.location,
  router: ownProps.router,
  children: ownProps.children,
});

export default withRouter(connect(mapStateToProps)(NDCPage));
