import { INCORRECT_SECURITY_CODE, VALIDATE_SECURITY_CODE_LOADING, VERIFIED_SECURITY_CODE } from '../../reducers/loginReducers';
import {post, postWithParams} from '../../utils/api';

const tokenVerified = () => ({
  type: VERIFIED_SECURITY_CODE,
});

const incorrectToken = response => ({
  type: INCORRECT_SECURITY_CODE,
  response,
});

export const verifyToken = (url, securityCode, params) => (dispatch) => {
  dispatch({ type: VALIDATE_SECURITY_CODE_LOADING });
  return postWithParams(url, { securityCode }, params)
    .then(() => dispatch(tokenVerified()))
    .catch(e => dispatch(incorrectToken(e.response.data)));
};
