import * as React from "react";
import {Col, Row} from "react-bootstrap";

export const PdapPaymentHistory = () => {
    return (
        <div className="contact">
            <Row>
                <Col>
                    If you have any questions regarding these transaction(s) or about your Principal® Dental Access membership,
                    please contact our Member Services Team toll-free at <strong>1-833-201-0142</strong>, Monday to
                    Friday 8:00am to
                    5:00pm PST. Our Principal® Dental Access website (www.principaldentalaccess.com) provides convenient 24/7
                    access to look up participating dentists and all the Plan details.
                </Col>
            </Row>
            <br/>
            <Row>
                <strong>Our mailing address is:</strong>
            </Row>
            <Row>
                Principal® Dental Access Plan
            </Row>
            <Row>
                9445 Farnham Street, Ste 100
            </Row>
            <Row>
                San Diego, California 92123
            </Row>
            <Row className="copyright">
                <em>Copyright © {new Date().getFullYear()} Principal® Dental Access, All rights reserved.</em>
            </Row>
        </div>
    );
};