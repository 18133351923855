import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchMemberDetails } from '../../actions/memberDetails/FetchMemberAction';
import { getMember, hasError, isLoading } from '../../reducers/memberDetailsReducer';
import '../../styles/planSummary.css';

export class MemberWelcome extends React.Component {
  componentDidMount() {
    this.props.loadMember();
  }

  render() {
    return (<span>{!this.props.isLoading && !this.props.hasError &&
    <h3 className="memberName">Hi <strong>{this.props.member.firstName}!</strong></h3>}</span>);
  }
}

MemberWelcome.propTypes = {
  loadMember: PropTypes.func.isRequired,
  member: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => ({
  member: getMember(state),
  isLoading: isLoading(state),
  hasError: hasError(state),
});

export const mapDispatchToProps = dispatch => ({
  loadMember: () => {
    if (window.location.hostname.includes("principaldentalaccess")) {
      dispatch(fetchMemberDetails({ subtype: "PDAP" }));
    } else {
      dispatch(fetchMemberDetails({}));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberWelcome);
