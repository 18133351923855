import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchPaymentUpdate, paymentUpdateClicked } from '../../actions/paymentSummary/FetchPaymentUpdateActions';
import { getBodyFrom, getPaymentUpdateUrlFrom } from '../../reducers/paymentUpdateReducer';
import {fetchPaymentHistory} from "../../actions/paymentSummary/FetchPaymentHistoryAction";

export class PaymentMethodUpdate extends React.Component {
  componentDidMount() {
    this.props.fetchPaymentUpdate();
  }

  render() {
    const hiddenFields = Object.keys(this.props.body).map(key =>
      (<input
        type="hidden"
        key={key}
        name={key}
        id={key}
        value={this.props.body[key]}
      />),
    );
    return (
      <span>
        <form method="post" action={this.props.paymentUpdateUrl}>
          {hiddenFields}
          <input
            type="submit"
            name="submit"
            value="Change payment source &#xf105;"
            className="btn-link fa fa-angle-right"
            onClick={this.props.paymentUpdateClicked}
          />
        </form>
      </span>
    );
  }
}

export const mapStateToProps = state => ({
  paymentUpdateUrl: getPaymentUpdateUrlFrom(state),
  body: getBodyFrom(state),
});

export const mapDispatchToProps = dispatch => ({
  fetchPaymentUpdate: () => {
    if (window.location.hostname.includes("principaldentalaccess")) {
      dispatch(fetchPaymentUpdate({ subtype: "PDAP" }));
    } else {
      dispatch(fetchPaymentUpdate({}));
    }
  },
  paymentUpdateClicked: () => dispatch(paymentUpdateClicked()),
});

PaymentMethodUpdate.propTypes = {
  body: PropTypes.shape().isRequired,
  paymentUpdateUrl: PropTypes.string.isRequired,
  fetchPaymentUpdate: PropTypes.func.isRequired,
  paymentUpdateClicked: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodUpdate);
