import PropTypes from 'prop-types';
import React from 'react';
import {PdapSupportPhoneNumber, SupportPhoneNumber} from './SupportPhoneNumber';

export const AccountLockedErrorMessage = props => (
  <div className="alert alert-warning alert-icon alert-dismissible" role="alert">
    Oops! Too many failed login attempts. For your security, your
    account has been temporarily locked for 60 minutes. You can
    try again in {props.minutesLockedFor} minutes.
    For immediate assistance, call us at {props.isPDAP ? <PdapSupportPhoneNumber/> : <SupportPhoneNumber/>}.
  </div>);

AccountLockedErrorMessage.propTypes = {
  minutesLockedFor: PropTypes.string.isRequired,
  isPDAP: PropTypes.bool,
};
