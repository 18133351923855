import * as React from "react";
import {Col, Row} from "react-bootstrap";

export const NdcPaymentHistory = () => {
    return (
        <div className="contact">
            <Row>
                <Col>
                    If you have any questions regarding these transaction(s) or about your New Dental Choice membership,
                    please contact our Member Services Team toll-free at <strong>1-888-632-3676</strong>, Monday to
                    Friday 8:00am to
                    5:00pm PST. Our New Dental Choice website (www.newdentalchoice.com) provides convenient 24/7
                    access to look up participating dentists and all the Plan details.
                </Col>
            </Row>
            <br/>
            <Row>
                <strong>Our mailing address is:</strong>
            </Row>
            <Row>
                P.O. Box 919029
            </Row>
            <Row>
                San Diego, California 92121
            </Row>
            <Row className="copyright">
                <em>Copyright © {new Date().getFullYear()} New Dental Choice, All rights reserved.</em>
            </Row>
        </div>
    );
};