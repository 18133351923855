import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchMemberDetails } from '../../actions/memberDetails/FetchMemberAction';
import { fetchPlanSummary } from '../../actions/planSummary/FetchSummaryAction';
import { isLoading as areDependentsLoading } from '../../reducers/dependentsReducer';
import { getMember } from '../../reducers/memberDetailsReducer';
import { getPlan, hasError, isLoading } from '../../reducers/planSummaryReducers';
import '../../styles/planSummary.css';
import { FAMILY_UPDATE } from '../../utils/Routes';
import { FetchPanel } from '../commons/FetchPanel';
import ConnectedDependents from '../dependent/Dependents';
import Person from '../dependent/Person';
import PlanDetails from './PlanDetails';
import PlanStatus from './PlanStatus';

export class PlanSummary extends React.Component {
  componentDidMount() {
    this.props.loadPlanSummary();
    this.props.loadMember();
  }

  render() {
    const title = <span>Plan summary <PlanStatus status={this.props.plan.status} /></span>;
    const footer = this.props.plan.status === 'Active'
      ? <Link to={FAMILY_UPDATE}>Make changes &#xf105;</Link>
      : undefined;

    return (
      <FetchPanel
        isLoading={this.props.isLoading}
        title={title}
        hasError={this.props.hasError}
      >
        <Panel header={title} footer={footer}>
          <PlanDetails plan={this.props.plan} />
          <div className="dependents">
            <Person person={this.props.member} />
            {this.props.plan.status === 'Active' && <ConnectedDependents />}
          </div>
        </Panel>
      </FetchPanel>);
  }
}

PlanSummary.propTypes = {
  loadMember: PropTypes.func.isRequired,
  loadPlanSummary: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  member: PropTypes.shape().isRequired,
};

export const mapStateToProps = state => ({
  member: getMember(state),
  plan: getPlan(state),
  isLoading: isLoading(state) && areDependentsLoading(state),
  hasError: hasError(state),
});

export const mapDispatchToProps = dispatch => ({
  loadPlanSummary: () => {
    if (window.location.hostname.includes("principaldentalaccess")) {
      dispatch(fetchPlanSummary({ subtype: "PDAP" }));
    } else {
      dispatch(fetchPlanSummary({}));
    }
  },
  loadMember: () => {
    if (window.location.hostname.includes("principaldentalaccess")) {
      dispatch(fetchMemberDetails({ subtype: "PDAP" }));
    } else {
      dispatch(fetchMemberDetails({}));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSummary);
