import React from 'react';
import {PdapSupportPhoneNumber, SupportPhoneNumber} from './SupportPhoneNumber';
import PropTypes from "prop-types";
import {AccountLockedErrorMessage} from "./AccountLockedErrorMessage";

export const ApplicationErrorMessage = props => (
  <div className="alert alert-warning alert-icon alert-dismissible" role="alert">
    Our website is currently undergoing maintenance. We apologize if information you normally obtain from our site is currently unavailable.
    For immediate assistance, call us at {props.isPDAP ? <PdapSupportPhoneNumber/> : <SupportPhoneNumber/>}.
  </div>);

ApplicationErrorMessage.propTypes = {
    isPDAP: PropTypes.bool,
};