import { RESEND_SECURITY_CODE, SEND_SECURITY_CODE_LOADING, SEND_TOKEN_FAILURE, SHOW_ACCEPT_TOKEN } from '../../reducers/loginReducers';
import {post, postWithParams} from '../../utils/api';

const showAcceptToken = response => ({
  type: SHOW_ACCEPT_TOKEN,
  response,
});

const showTokenSendFailureMessage = () => ({
  type: SEND_TOKEN_FAILURE,
});

export const resendSecurityCode = () => dispatch => dispatch({ type: RESEND_SECURITY_CODE });

export const sendToken = (url, params) => (dispatch) => {
  dispatch({ type: SEND_SECURITY_CODE_LOADING });
  return postWithParams(url, {}, params)
    .then(({ data }) => dispatch(showAcceptToken(data)))
    .catch(() => dispatch(showTokenSendFailureMessage()));
};
