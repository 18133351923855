import {ACCOUNT_OVERVIEW, NDC_FAQ, LOGOUT, PAYMENT_HISTORY} from '../../utils/Routes';


export const NavItems =
  [
    { label: 'Account Overview', route: ACCOUNT_OVERVIEW },
    { label: 'Payment History', route: PAYMENT_HISTORY },
    { label: 'FAQ\'s', route: NDC_FAQ },
    { label: 'Log out', route: LOGOUT, position: 'right' },
  ];

export const PdapNavItems = (isStaging) => {
    let pdapFAQ;
    if (isStaging) {
        pdapFAQ = 'https://test-principal-dental-access-plan.pantheonsite.io/home/frequently-asked-questions';
    } else {
        pdapFAQ = 'https://www.principaldentalaccess.com/home/frequently-asked-questions';
    }
    return [
        {label: 'Account Overview', route: ACCOUNT_OVERVIEW},
        {label: 'Payment History', route: PAYMENT_HISTORY},
        {label: 'FAQ\'s', route: pdapFAQ},
        {label: 'Log out', route: LOGOUT, position: 'right'},
    ];
}
