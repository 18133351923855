import { MEMBER_DETAILS_FAILED, MEMBER_DETAILS_LOADED, MEMBER_DETAILS_LOADING } from '../../reducers/memberDetailsReducer';
import { MEMBER_DETAILS } from '../../utils/Links';

export const fetchMemberDetails = params => ({
  urlName: MEMBER_DETAILS,
  loaded: MEMBER_DETAILS_LOADED,
  initiate: MEMBER_DETAILS_LOADING,
  failure: MEMBER_DETAILS_FAILED,
  params,
});
