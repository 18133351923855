import { PLAN_DETAILS_FAILED, PLAN_DETAILS_LOADED, PLAN_DETAILS_LOADING } from '../../reducers/planSummaryReducers';
import { PLAN_SUMMARY } from '../../utils/Links';

export const fetchPlanSummary = params => ({
  urlName: PLAN_SUMMARY,
  initiate: PLAN_DETAILS_LOADING,
  failure: PLAN_DETAILS_FAILED,
  loaded: PLAN_DETAILS_LOADED,
  params,
});
