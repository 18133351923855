import React from 'react';
import '../../styles/footer.css';
import {PdapSupportPhoneNumber} from '../commons/SupportPhoneNumber';
import PropTypes from "prop-types";

const PDAPFooter = (props) => {
  let links = {}
  if (props.isStaging) {
    links.TERMS_OF_USE = 'https://test-principal-dental-access-plan.pantheonsite.io/terms-use';
    links.PRIVACY_POLICY = 'https://test-principal-dental-access-plan.pantheonsite.io/privacy-policy';
    links.CONTACT_US = 'https://test-principal-dental-access-plan.pantheonsite.io/home/contact-us';
    links.DISCLOSURES = 'https://test-principal-dental-access-plan.pantheonsite.io/disclosure-form';
  } else {
    links.TERMS_OF_USE = 'https://principaldentalaccess.com/terms-use';
    links.PRIVACY_POLICY = 'https://principaldentalaccess.com/privacy-policy';
    links.CONTACT_US = 'https://principaldentalaccess.com/home/contact-us';
    links.DISCLOSURES = 'https://principaldentalaccess.com/disclosure-form';
  }
  return (
    <div>
      <div className="footer big-footer hidden-xs">
        <div className="spacer" />
        <div className="info">
          <div className="info-row">
            <i className="fa fa-phone" aria-hidden="true" />
            &nbsp;<PdapSupportPhoneNumber /> (Se Habla Español)
            &nbsp;|&nbsp;<a href={links.CONTACT_US} target="_blank" rel="noopener noreferrer">
              <i className="fa fa-envelope" aria-hidden="true" />
            &nbsp;Contact Us</a>
          </div>
          <div className="info-row">
            <a target="_blank" rel="noopener noreferrer" href={links.TERMS_OF_USE}>Terms of Use</a>
            &nbsp;|&nbsp;<a target="_blank" rel="noopener noreferrer" href={links.PRIVACY_POLICY}>Policies</a>
            &nbsp;|&nbsp;<a target="_blank" rel="noopener noreferrer" href={links.DISCLOSURES}>Disclosures</a>
          </div>
        </div>
      </div>
      <div className="footer small-footer visible-xs">
        <div className="spacer" />
        <div className="info">
          <div>
            <i className="fa fa-phone" aria-hidden="true" />
            <PdapSupportPhoneNumber /><br /><b>(se habla español)</b><br />
            <a target="_blank" rel="noopener noreferrer" href={links.CONTACT_US}>Contact Us</a><br />
            <a target="_blank" rel="noopener noreferrer" href={links.TERMS_OF_USE}>Terms of Use</a><br />
            <a target="_blank" rel="noopener noreferrer" href={links.PRIVACY_POLICY}>Policies</a><br />
            <a target="_blank" rel="noopener noreferrer" href={links.DISCLOSURES}>Disclosures</a><br />
          </div>
        </div>
      </div>
    </div>
  );
};

PDAPFooter.propTypes = {
  isStaging: PropTypes.bool.isRequired,
};

export default PDAPFooter;
