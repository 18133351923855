import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchMemberDetails } from '../../actions/memberDetails/FetchMemberAction';
import { saveRequiredInformation, updateMemberProperty } from '../../actions/memberDetails/UpdateMemberAction';
import { areMemberDetailsRequired, getMember } from '../../reducers/memberDetailsReducer';
import '../../styles/requireInformationModal.css';
import EmailTextField from './EmailTextField';
import Form from './Form';
import PhoneTextField from './PhoneTextField';
import Submit from './Submit';

export class RequireInformationModal extends Component {
  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    this.props.loadMemberDetails();
  }

  onFormSubmit() {
    this.props.saveMember(this.props.member);
  }

  render() {
    const { emailAddress, phoneNumber, isSaving } = this.props.member;
    return (
      <div>
        {this.props.showModal &&
        <span>
          <div className="modal-backdrop" />
          <div className="modal show">
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <Form onSubmit={this.onFormSubmit}>
                  <div className="modal-header">
                    <span className="title">Additional Information</span>
                    <div className="row additional-information">
                      <div className="col-xs-12">
                        To better stay in touch regarding your account,
                        we need both your email address and cell phone
                        number in the fields shown. Please complete the
                        missing information or call 888.632.7558 with questions.
                      </div>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-xs-12">
                        <div
                          className={emailAddress ? 'form-group' : 'form-group has-error'}
                        >
                          <label
                            className="is-required"
                            htmlFor="emailAddress"
                          >Email address</label>
                          <EmailTextField
                            name="emailAddress"
                            className="email-address"
                            value={emailAddress}
                            onInput={this.props.updateEmailAddress}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <div
                          className={phoneNumber ? 'form-group' : 'form-group has-error'}
                        >
                          <label
                            className="is-required"
                            htmlFor="phoneNumber"
                          >Phone number</label>
                          <PhoneTextField
                            name="phoneNumber"
                            className="phone-number"
                            value={phoneNumber}
                            onChange={this.props.updatePhoneNumber}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="row">
                      <div className="col-xs-12 col-sm-4 pull-right">
                        <Submit
                          isLoading={isSaving}
                          disabled={!phoneNumber || !emailAddress}
                        >Submit</Submit>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </span>
        }
      </div>
    );
  }
}

RequireInformationModal.propTypes = {
  loadMemberDetails: PropTypes.func.isRequired,
  saveMember: PropTypes.func.isRequired,
  member: PropTypes.shape({
    emailAddress: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
  }).isRequired,
  showModal: PropTypes.bool.isRequired,
  updateEmailAddress: PropTypes.func.isRequired,
  updatePhoneNumber: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  member: getMember(state),
  showModal: areMemberDetailsRequired(state),
});

export const mapDispatchToProps = dispatch => ({
  loadMemberDetails: () => {
    if (window.location.hostname.includes("principaldentalaccess")) {
      dispatch(fetchMemberDetails({ subtype: "PDAP" }));
    } else {
      dispatch(fetchMemberDetails({}));
    }
  },
  updateEmailAddress: event => dispatch(updateMemberProperty('emailAddress', event.target.value)),
  updatePhoneNumber: event => dispatch(updateMemberProperty('phoneNumber', event.target.value)),
  saveMember: member => dispatch(saveRequiredInformation(member)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequireInformationModal);
