import cookie from 'browser-cookies';
import queryString from 'query-string';
import { getLocation, goTo } from './Location.service';

require('isomorphic-fetch');

const noQuery = url => url.split('?')[0];

const shouldRedirect = (url, status) => {
  const isSessionValidate = url.endsWith('/validate/session');
  const isApi = url.endsWith('/api');
  const isOAuth = url.includes('/oauth/token');

  return status === 401
    && !isOAuth
    && ((!getLocation().endsWith('/logout') && !getLocation().endsWith('/login'))
      || (getLocation().endsWith('/login') && (!isSessionValidate && !isApi)));
};

function parseJson(response) {
  return new Promise((resolve) => {
    const json = response.json();
    json.then((data) => {
      response.parsedJson = data;
      resolve(response);
    }).catch(() => {
      response.parsedJson = {};
      resolve(response);
    });
  });
}

const qs = params => (params ? `?${queryString.stringify(params)}` : '');

const getResponse = (status, parsedJson) => ({ status, data: parsedJson });

const throwStatusError = (statusText, status, parsedJson) => {
  const error = new Error(statusText);
  error.response = getResponse(status, parsedJson);
  throw error;
};

const checkStatus = ({ status, statusText, parsedJson }, url) => {
  if (shouldRedirect(url, status)) {
    goTo('/logout');
  }
  if (status >= 200 && status < 400) {
    return getResponse(status, parsedJson);
  }

  return throwStatusError(statusText, status, parsedJson);
};

const fetcher = (method, url, params, body) =>
  fetch(
    `${noQuery(url)}${qs(params)}`,
    {
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': cookie.get('crumb'),
        'Content-Type': 'application/json',
      },
      method,
      body: JSON.stringify(body),
    })
    .then(parseJson)
    .then(response => checkStatus(response, url));

export const get = (url, params) => fetcher('GET', url, params);
export const post = (url, body) => fetcher('POST', url, undefined, body);
export const postWithParams = (url, body, params) => fetcher('POST', url, params, body);
export const patch = (url, body, params) => fetcher('PATCH', url, params, body);
export const remove = (url, id, params) => fetcher('DELETE', url, params);
