import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { removeFamilyMember, toggleModal } from '../../actions/memberDetails/DependentsActions';
import { isLastFamilyMember } from '../../reducers/dependentsReducer';
import Alert from '../commons/Alert';
import Form from '../commons/Form';
import Submit from '../commons/Submit';

export class FamilyMemberRemoveConfirmationModal extends React.Component {
  getErrors = (personErrors, isLastFamilyMemberProp, supportNumber) => ((isLastFamilyMemberProp)
    ? [{
      propertyName: 'dependentCount',
      message: 'Sorry but removing this dependent would change your membership type, so this action is not available online.  ' +
      `Please call ${supportNumber} if you’d like to continue.`,
    }]
    : personErrors);

  remove =
    () => this.props.removeFamilyMember(this.props.person._links.delete.href, this.props.person);

  hideModal = () => this.props.hideModal(this.props.person.id);
  
  supportNumber = window.location.hostname.includes("principaldentalaccess") ? '(833) 201-0142' : '888.632.3676';

  render() {
    const {
      firstName,
      lastName,
      isRemoving,
      removeErrors,
      showRemoveModal,
    } = this.props.person || {};
    return (
      <Modal
        backdrop
        show={showRemoveModal}
      >
        <Modal.Header>
          <Modal.Title><h2 className="modal-title">Remove family member</h2></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Alert errors={this.getErrors(removeErrors, this.props.isLastFamilyMember, this.supportNumber)} />
          {!this.props.isLastFamilyMember &&
          <span>Just making sure you want to remove <strong>{firstName} {lastName}</strong>?</span>
          }
        </Modal.Body>

        <Modal.Footer>
          {!this.props.isLastFamilyMember &&
          <Form onSubmit={this.remove}>
            <Submit
              className="btn btn-danger"
              isLoading={isRemoving}
            >
              Remove
            </Submit>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Form>}
          {this.props.isLastFamilyMember &&
          <button
            type="button"
            className="btn btn-default"
            onClick={this.hideModal}
          >
            Close
          </button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

FamilyMemberRemoveConfirmationModal.propTypes = {
  removeFamilyMember: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  person: PropTypes.shape().isRequired,
  isLastFamilyMember: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => ({
  isLastFamilyMember: isLastFamilyMember(state),
});

export const mapDispatchToProps = dispatch => ({
  removeFamilyMember: (url, familyMember) => dispatch(removeFamilyMember(url, familyMember)),
  hideModal: key => dispatch(toggleModal(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyMemberRemoveConfirmationModal);
