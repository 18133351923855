import {
  DEPENDENTS_FAILED,
  DEPENDENTS_LOADED,
  DEPENDENTS_LOADING,
  DEPENDENTS_NEW_FAILED,
  DEPENDENTS_NEW_INITIATED,
  DEPENDENTS_NEW_SUCCESS,
  DEPENDENTS_REMOVE_FAILED,
  DEPENDENTS_REMOVE_INITIATED,
  DEPENDENTS_REMOVE_SUCCESS,
  DEPENDENTS_TOGGLE_MODAL,
  DEPENDENTS_UPDATE_FAILED,
  DEPENDENTS_UPDATE_INITIATED,
  DEPENDENTS_UPDATE_SUCCESS,
  FAMILY_MEMBER_ADD,
  FAMILY_MEMBER_UPDATE_PROPERTY,
} from '../../reducers/dependentsReducer';
import {
  DEPENDENTS_NOT_ENROLLED_FAILED,
  DEPENDENTS_NOT_ENROLLED_LOADED,
  DEPENDENTS_NOT_ENROLLED_LOADING,
  DEPENDENTS_RE_ENROLL_FAILED,
  DEPENDENTS_RE_ENROLL_INITIATED,
  DEPENDENTS_RE_ENROLL_SUCCESS,
} from '../../reducers/notEnrolledDependentsReducer';
import {patch, post, postWithParams, remove} from '../../utils/api';
import { DEPENDENTS, NOT_ENROLLED_DEPENDENTS } from '../../utils/Links';
import { update as updateMember } from './UpdateMemberAction';

export const fetchNotEnrolledDependents = params => ({
  urlName: NOT_ENROLLED_DEPENDENTS,
  loaded: DEPENDENTS_NOT_ENROLLED_LOADED,
  initiate: DEPENDENTS_NOT_ENROLLED_LOADING,
  failure: DEPENDENTS_NOT_ENROLLED_FAILED,
  params
});

export const fetchDependents = params => ({
  urlName: DEPENDENTS,
  loaded: DEPENDENTS_LOADED,
  initiate: DEPENDENTS_LOADING,
  failure: DEPENDENTS_FAILED,
  params
});

export const reEnrollFamilyMember = familyMember => (dispatch) => {
  const { id, _links } = familyMember;
  dispatch({ type: DEPENDENTS_RE_ENROLL_INITIATED, key: id });
  let params = window.location.hostname.includes("principaldentalaccess") ? { subtype: "PDAP" } : {};
  return patch(_links.reinstate.href, {}, params)
    .then(({ data }) => dispatch({ type: DEPENDENTS_RE_ENROLL_SUCCESS, resource: data, key: id }))
    .catch(({ response }) =>
      dispatch({ type: DEPENDENTS_RE_ENROLL_FAILED, key: id, resource: response.data }));
};

export const removeFamilyMember = (url, familyMember) => (dispatch) => {
  const { id } = familyMember;
  dispatch({ type: DEPENDENTS_REMOVE_INITIATED, key: id });
  let params = window.location.hostname.includes("principaldentalaccess") ? { subtype: "PDAP" } : {};
  return remove(url, id, params)
    .then(({ data }) => dispatch({ type: DEPENDENTS_REMOVE_SUCCESS, resource: data, key: id }))
    .catch(({ response }) =>
      dispatch({ type: DEPENDENTS_REMOVE_FAILED, key: id, resource: response.data }));
};

export const toggleModal = key => ({
  type: DEPENDENTS_TOGGLE_MODAL,
  key,
});

export const addFamilyMember = () => ({
  type: FAMILY_MEMBER_ADD,
});

const updateFamilyMember = (dispatch, person) => {
  dispatch({ type: DEPENDENTS_UPDATE_INITIATED, key: person.id });
  let params = window.location.hostname.includes("principaldentalaccess") ? { subtype: "PDAP" } : {};
  return patch(person._links.update.href, person, params)
    .then(({ data }) =>
      dispatch({ type: DEPENDENTS_UPDATE_SUCCESS, resource: data, key: person.id }))
    .catch(({ response }) => {
      dispatch({ type: DEPENDENTS_UPDATE_FAILED, key: person.id, resource: response.data });
    });
};

export const saveUpdatedFamilyMember = person => dispatch => ((person.relationshipType === 'Member')
  ? updateMember(person)(dispatch)
  : updateFamilyMember(dispatch, person));

export const saveNewFamilyMember = (url, person) => (dispatch) => {
  dispatch({ type: DEPENDENTS_NEW_INITIATED, key: person.id });
  let params = window.location.hostname.includes("principaldentalaccess") ? { subtype: "PDAP" } : {};
  return postWithParams(url, person, params)
    .then(({ data }) => dispatch({ type: DEPENDENTS_NEW_SUCCESS, resource: data, key: person.id }))
    .catch(({ response }) => {
      dispatch({ type: DEPENDENTS_NEW_FAILED, key: person.id, resource: response.data });
    });
};

export const updateFamilyMemberProperty = (property, value, key) => ({
  type: FAMILY_MEMBER_UPDATE_PROPERTY,
  property,
  value,
  key,
});
