import PropTypes from 'prop-types';
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Alert } from 'react-bootstrap';
import printJS from 'print-js';
import { connect } from 'react-redux';
import { isLoading, hasError, base64EncodedCardData } from '../../reducers/memberCardReducer';
import { fetchMemberCardDetails, clearMemberCardDetails } from '../../actions/memberCard/MemberCardAction';
import Submit from '../commons/Submit';

export class MemberCardPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = { base64EncodedCardData: '' };
  }

  memberCardPrintAlert = () => (<Alert bsStyle="warning">
    <p>We seem to be having trouble printing your id card.  Please try again later.</p>
  </Alert>);

  render() {
    if (this.props.base64EncodedCardData) {
      printJS({ printable: this.props.base64EncodedCardData, type: 'pdf', base64: true });
      this.props.clearMemberCardDetails();
    }
    let cardAlert = null;
    if (this.props.hasError) {
      cardAlert = this.memberCardPrintAlert();
    }
    return (
      <div>
        {cardAlert}
        <Submit
          onClick={this.props.fetchMemberCardDetails}
          isLoading={this.props.isLoading}
        >Print Id Card</Submit>
      </div>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  fetchMemberCardDetails: () => {
    let params = window.location.hostname.includes("principaldentalaccess") ? { subtype: "PDAP" } : {};
    dispatch(fetchMemberCardDetails(params))
  },
  clearMemberCardDetails: () => dispatch(clearMemberCardDetails()),
});

export const mapStateToProps = state => ({
  base64EncodedCardData: base64EncodedCardData(state),
  isLoading: isLoading(state),
  hasError: hasError(state),
});

MemberCardPrint.propTypes = {
  fetchMemberCardDetails: PropTypes.func.isRequired,
  clearMemberCardDetails: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberCardPrint);
