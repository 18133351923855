import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { resendSecurityCode } from '../../actions/login/SendTokenAction';
import { verifyToken } from '../../actions/login/VerifyTokenAction';
import {
  getIsValidateSecurityCodeLoading,
  hasError,
  isAccountLocked,
  minutesLockedFor,
  verifySecurityCodeLink,
} from '../../reducers/loginReducers';
import { AccountLockedErrorMessage } from '../commons/AccountLockedErrorMessage';
import { AlphaNumericTextField } from '../commons/AlphaNumericTextField';
import Form from '../commons/Form';
import Submit from '../commons/Submit';
import ResendSecurityCodeModal from './ResendSecurityCodeModal';
import {PdapSupportPhoneNumber, SupportPhoneNumber} from "../commons/SupportPhoneNumber";

export class AcceptLoginToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loginToken: '' };
  }

  _validationErrorsExist = () => !this.state.loginToken;

  updateLoginToken = event => this.setState({ loginToken: event.target.value });

  validateToken = () => this.props.verifySecurityCode(this.props.verifySecurityCodeLink, this.state.loginToken);
  isPDAP= window.location.hostname.includes("principaldentalaccess")
  errorMessage() {
    if (this.props.hasError) {
      return (
        <div className="row">
          <div className="alert alert-warning alert-icon alert-dismissible" role="alert">
            We&apos;re sorry, it looks like your security code is incorrect.
            You can try again or call customer support at {this.isPDAP ? <PdapSupportPhoneNumber/> : "888-632-7558"}.
          </div>
        </div>);
    }
    return (<span />);
  }

  render() {
    return (
      <Form onSubmit={this.validateToken}>
        {this.errorMessage()}
        {this.props.isAccountLocked &&
        <AccountLockedErrorMessage minutesLockedFor={this.props.minutesLockedFor} />
        }
        <div className="row">
          <div className="col-md-10">
            <h1>Your unique security code has been sent.</h1>
            <p>Enter your security code below to access your account.</p>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="loginToken" className="is-required">Security code</label>
              <AlphaNumericTextField
                name="loginToken"
                value={this.state.loginToken}
                className="loginToken"
                maxLength="6"
                onInput={this.updateLoginToken}
              />
              <a className="login-help-link" data-toggle="modal" data-target=".modal">I did
                not
                receive a security code</a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <br />
            <Submit
              isLoading={this.props.isLoading}
              disabled={this._validationErrorsExist()}
            >Log in</Submit>
          </div>
        </div>
        <ResendSecurityCodeModal resendSecurityCode={this.props.resendSecurityCode} isPDAP={this.isPDAP} />
      </Form>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  verifySecurityCode: (url, token) => {
    let params = window.location.hostname.includes("principaldentalaccess") ? { subtype: "PDAP" } : {};
    dispatch(verifyToken(url, token, params))
  },
  resendSecurityCode: () => dispatch(resendSecurityCode()),
});

export const mapStateToProps = state => ({
  isLoading: getIsValidateSecurityCodeLoading(state),
  verifySecurityCodeLink: verifySecurityCodeLink(state),
  hasError: hasError(state),
  minutesLockedFor: minutesLockedFor(state),
  isAccountLocked: isAccountLocked(state),
});

AcceptLoginToken.propTypes = {
  resendSecurityCode: PropTypes.func.isRequired,
  verifySecurityCode: PropTypes.func.isRequired,
  verifySecurityCodeLink: PropTypes.string.isRequired,
  minutesLockedFor: PropTypes.string.isRequired,
  isAccountLocked: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptLoginToken);
