import PropTypes from 'prop-types';
import React from 'react';
import pdapLogo from './PdapLogoFront.png';
import {PRIMARY} from '../../properties/colors';
import './pdapMemberCardFront.css';
import logo from './LogoCorner.png';

export const PdapMemberCardFront = ({contractId, firstName, lastName, startDate, isStaging}) => {
    let homeLink;
    if (isStaging) {
        homeLink = "https://test-principal-dental-access-plan.pantheonsite.io/";
    } else {
        homeLink = "https://principaldentalaccess.com";
    }
    return (
        <div style={{border: '1px solid #464646', borderRadius: '25px', padding: '15px', fontSize: '.7em'}}>
            <img className="img-responsive logo center-block" src={pdapLogo} alt="Principal Dental Access Logo"/>
            <table className="bottomPad">
                <tr>
                    <td className="member-card-label">Membership ID#:</td>
                    <td>{contractId}</td>
                </tr>
                <tr>
                    <td>Member name:</td>
                    <td>{firstName} {lastName}</td>
                </tr>
                <tr>
                    <td>Effective date:</td>
                    <td>{startDate}</td>
                </tr>
            </table>
            <span style={{fontSize: '.9em'}}>
                <div>For participating dentists and dentist fees, click or call:</div>
                <div className="bottomPad" style={{color: PRIMARY}}><strong>{homeLink} | (833) 201-0142</strong></div>
                <div className="flex-container">
                    <div>
                        <strong>Principal Dental Access is NOT insurance. </strong><br/>
                        <small>It is a discount dental plan provided by Principal.</small>
                        <br/>
                        <small><small>062916MC</small></small>
                    </div>
                    <img src={logo} alt="PDAP Logo Corner" className="logo-corner"/>
                </div>
            </span>
        </div>);
};

PdapMemberCardFront.propTypes = {
    contractId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
};
