import React from 'react';

export const PdapElectronicConsent = () => (
    <div>
        <i>Please read the following information. You are not required to conduct business
            electronically or to receive or sign documents electronically.</i>
        <br />
        <br />
        By consenting to do business electronically with Principal® Dental Access Plan you agree that:
        <br />
        <strong>you agree that</strong>:
        <br />
        <br />
        <ul>
            <li>You have read and agree to our disclosures, terms of use and privacy policies.</li>
            <li>You are who you say you are when logging in and transacting business through our
                website.
            </li>
            <li>This consent applies to any transactions conducted through our website with respect to
                your discount dental plan, such as enrolling, managing participants, managing payment
                information, etc.
            </li>
            <li>You have the right to withdraw this consent at any time by calling 833-201-0142. Once we
                have received notice that you have withdrawn your consent, your request will be effective
                as soon as reasonably possible.
            </li>
            <li>You will need access to a computer with a current internet browser and an operating
                system that allows you to save files or print web pages and documents. See terms of use
                for more information.
            </li>
            <li>You may print this consent and any electronic record, or you can contact us to request a
                paper copy for free.
            </li>
            <li>You will notify us of changes to your email, phone number, or mailing address by logging
                in to the My Account section or calling 833-201-0142.
            </li>
            <li>United States laws govern our sites and your use of them.</li>
            <li>Your online activity may be viewed, monitored and recorded to help prevent and detect
                fraud.
            </li>
            <li>We may contact you by email, telephone, or text message as indicated in the My Account
                section.
            </li>
        </ul>
        <br />
        If you do not consent, you will not be able to access account information or conduct
        transactions online.
    </div>
);
