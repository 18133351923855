import PropTypes from 'prop-types';
import React from 'react';
import { Button, Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toggleMemberCard } from '../../actions/memberCard/MemberCardAction';
import { fetchMemberDetails } from '../../actions/memberDetails/FetchMemberAction';
import { fetchPlanSummary } from '../../actions/planSummary/FetchSummaryAction';
import { showFront } from '../../reducers/memberCardReducer';
import { getMember, hasError as hasMemberError, isLoading as isMemberLoading } from '../../reducers/memberDetailsReducer';
import { getPlan, hasError as hasPlanError, isLoading as isPlanLoading } from '../../reducers/planSummaryReducers';
import { FetchPanel } from '../commons/FetchPanel';
import { MemberCardBack } from './MemberCardBack';
import back from './MemberCardBack.png';
import ConnectedMemberCardPrint from './MemberCardPrint';
import { MemberCardFront } from './MemberCardFront';
import './memberCardPanel.css';
import {PdapMemberCardFront} from "./pdapMemberCardFront";
import {PdapMemberCardBack} from "./pdapMemberCardBack";

export class MemberCardPanel extends React.Component {
  componentDidMount = () => {
    new Image().src = back;
    this.props.loadPlan();
    this.props.loadMember();
  };

  isPDAP= window.location.hostname.includes("principaldentalaccess");
  isStaging = window.location.hostname.includes("staging");

  render() {
    return (
      <FetchPanel title="ID Card" isLoading={this.props.isLoading} hasError={this.props.hasError}>
        <Panel header="ID Card" footer={this.footer}>
          <div className="memberCard">
            {this.props.showFront ?
                this.isPDAP ? <PdapMemberCardFront
                    startDate={this.props.plan.startDate}
                    lastName={this.props.member.lastName}
                    firstName={this.props.member.firstName}
                    contractId={this.props.plan.contractId}
                    isStaging={this.isStaging}
                /> : <MemberCardFront
                    startDate={this.props.plan.startDate}
                    lastName={this.props.member.lastName}
                    firstName={this.props.member.firstName}
                    contractId={this.props.plan.contractId}
                />
              : this.isPDAP ? <PdapMemberCardBack /> : <MemberCardBack />}
            <div className="memberCardButtons">
              <Button bsStyle="link" onClick={this.props.toggleMemberCard} disabled={this.props.showFront}>Front</Button>
              &nbsp;| <Button bsStyle="link" onClick={this.props.toggleMemberCard} disabled={!this.props.showFront}>Back</Button>
            </div>
            <div className="memberCardPrint">
              <ConnectedMemberCardPrint />
            </div>
          </div>
        </Panel>
      </FetchPanel>
    );
  }
}

MemberCardPanel.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  plan: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    contractId: PropTypes.string.isRequired,
  }).isRequired,
  member: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  loadPlan: PropTypes.func.isRequired,
  loadMember: PropTypes.func.isRequired,
  toggleMemberCard: PropTypes.func.isRequired,
  showFront: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => ({
  isLoading: isPlanLoading(state) && isMemberLoading(state),
  hasError: hasPlanError(state) && hasMemberError(state),
  plan: getPlan(state),
  member: getMember(state),
  showFront: showFront(state),
});

export const mapDispatchToProps = dispatch => ({
  loadPlan: () => dispatch(fetchPlanSummary()),
  loadMember: () => {
    if (window.location.hostname.includes("principaldentalaccess")) {
      dispatch(fetchMemberDetails({ subtype: "PDAP" }));
    } else {
      dispatch(fetchMemberDetails({}));
    }
  },
  toggleMemberCard: () => dispatch(toggleMemberCard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberCardPanel);
