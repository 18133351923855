import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchDependents } from '../../actions/memberDetails/DependentsActions';
import { getDependentsFrom } from '../../reducers/dependentsReducer';
import '../../styles/planSummary.css';
import Person from './Person';
import {fetchPlanSummary} from "../../actions/planSummary/FetchSummaryAction";

class Dependents extends React.Component {
  componentDidMount() {
    this.props.fetchDependents();
  }

  render() {
    return (
      <div>
        {this.props.dependents.map(dependent =>
          <Person person={dependent} key={dependent.contactId} />)}
      </div>
    );
  }
}

Dependents.propTypes = {
  fetchDependents: PropTypes.func.isRequired,
  dependents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  dependents: getDependentsFrom(state),
});

const mapDispatchToProps = dispatch => ({
  fetchDependents: () => {
    if (window.location.hostname.includes("principaldentalaccess")) {
      dispatch(fetchDependents({ subtype: "PDAP" }));
    } else {
      dispatch(fetchDependents({}));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dependents);
