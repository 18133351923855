/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from "prop-types";

const ExplainPasswordlessLoginModal = props => (
  <div className="modal fade">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true"
          >&times;</span></button>
          <h2 className="modal-title">{props.siteName} secure and easy login</h2>
        </div>
        <div className="modal-body">
          <p>
            We've made it secure and convenient to log in to your {props.siteName} account. How does this work?
          </p>
          <br />
          <ul>
            <li>
              When you signed up for {props.siteName},
              you provided us with your email address and a contact phone number.
              That's all we need for your site registration.
            </li>
            <li>
              We know you have a million passwords memorized,
              so we're not asking you to remember another.
              Each time you log in, we'll generate a unique
              security code that will enable you to access your account,
              and we'll send that code to you by email or text to your
              contact phone number – you decide.
            </li>
            <li>Just enter your security code to access your account details.</li>
          </ul>
          <br />
          With {props.siteName}'s secure and easy login,
          there's nothing to remember, so there's nothing to forget!
          <br />
          <br />
          <br />
          <small><sup>*</sup>Standard messaging rates apply.</small>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
);

ExplainPasswordlessLoginModal.propTypes = {
  siteName: PropTypes.string,
};

export default ExplainPasswordlessLoginModal;
